.DrawerToggle {
    margin: 0 10px;
    padding: 15px 0;
    width: 40px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    z-index: 0;
}

.DrawerToggle div {
    width: 90%;
    height: 3px;
    background-color: black;
    margin:3px;
}


@media (min-width: 1200px) {
    .DrawerToggle {
        display: none;
    }
}