.Section {
	width: 95%;
	margin: 0 auto;
	margin-top: 30px;
	color: gray;
	line-height: 1.4em;
	padding-right: 15px;
	padding-left: 15px;
}

.Section:before { 
    display: block; 
    content: " "; 
    height: 80px;      /* Give height of your fixed element */
	margin-top: -80px; /* Give negative margin of your fixed element */    
	visibility: hidden; 
}

.Section h3 {
	margin: 30px auto;
}

.Discounts {
	font-size: 11pt;
	text-align: center;
}

.Section *:not(.Header, h3, .Discounts) {
	font-size: 11pt;
	text-align: justify;
}

.Section ul {
	list-style: outside;
	text-align: left;
	margin: 0 15px;
}

@media (min-width: 810px) {
	.Section {
		width: 750px;
	}
}

@media (min-width: 990px) {
	.Section {
		width: 870px;
	}
}
