.NavItemLink {
    cursor: pointer;
    color: #725757;
    padding: 10px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}


.NavItemLink:hover {
    background-color: #5a3d3d;
    color: white;
}


.NavItem {
    height: auto;
}

@media (max-width: 1199px) {
    .NavItemLink {
        width: 100%;
        padding:40px;
    }
}