.Toolbar {
    background-color: white;
    position: fixed;
    top: 0;
    height: 80px;
    width: 100%;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}


.InnerToolbar {
    width: 90%;
    height:100%;
    left: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.Logo {
    position: relative;
    box-sizing:border-box;
    top:0;
    left: 0;
}

.Logo img {
    height: 50px;
}


@media (max-width: 1199px) {
    .DesktopOnly {
        display: none;
    }
}


@media (min-width: 1200px) {
    .Logo img {
        height: 70px;
    }
    
    .InnerToolbar {
        width: 60%;
        left: 20%;
    }
    
    .Toolbar {
        height: 120px;
    }

    .DesktopOnly {
        height: 100%;
    }
}