.Discount {
    border-top: 2px solid rgb(235, 235, 235);
    width: 80%;
    margin: 15px;
    padding-top: 30px;
    text-align: center;
    line-height: 1.8em;
}

.Discount :first-child {
    padding-right: 10px;
}

.Blue {
    color: rgb(86, 212, 201);
}

.Brown {
    color: rgb(114, 87, 87);
}

@media (min-width: 1170px) {
    .Discount {
        width: 350px;
        text-align: justify;
    }
}