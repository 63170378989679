.Profile {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    margin: 0 0 30px 0;
}

.Text {
    color: gray;
    font-size: 11pt;
    line-height: 1.8em;
}
.Text p{
    padding-bottom: 10px;
}

.Name {
    font-weight: bold;
    color: #686868;
    padding-bottom: 20px;
    text-align: center;
}

@media (min-width: 1000px) {
    .Profile {
        margin: 0 30px 0 0;
    }
    .Name {
        text-align: justify;
    }
}