.Header {
    height: 650px;
    width:100%;
}

.Header h1 {
    padding-top: 150px;
}

.Dot {
    color: #725757;
}

.Header h2 {
    margin: 20px;
}

.Text {
    box-sizing: border-box;
    margin: 40px auto;
    color: #7a7a7a;
    line-height: 26px;
    font-size: 11pt;
    width: 80%;
}

.Text a {
    color: #725757;
    font-weight: bold;
}

@media (min-width: 1200px) {
    .Text {
        max-width: 730px;
    }
}

.Buttons {
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    width: 50%;
    height: 140px;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 1200px) {
    .Buttons {
        width: 520px;
        flex-flow: row;
        margin-top: 50px;
    }
    
    .Text {
        margin: 60px auto;
    }
}

@media (max-width: 650px ) {
    .Text {
        margin-bottom: 10px;
    }
}