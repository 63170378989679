.Discounts {
    width: 100%;
    margin: 10px;
    margin-top: 50px;
    display: flex;
    flex-flow: column;
    align-content: space-between;
    align-items: center;
    justify-content: space-between;
    font-size: 11pt;
}

@media (min-width: 1170px) {
    .Discounts {
        width: 1100px;
        flex-flow: row;
        margin: 0 auto;
        align-items: normal;
    }
}