.Main {
    padding-bottom: 50px;
}

.Main:before { 
    display: block; 
    content: " "; 
    height: 80px;      /* Give height of your fixed element */
	margin-top: -80px; /* Give negative margin of your fixed element */    
	visibility: hidden; 
}


.Packages {
    width: 90%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 800px) {
    .Packages {
        width: 750px;
        flex-flow: row;
        align-items: normal;
    }
}

@media (min-width: 1200px) {
    .Packages {
        width: 1100px;
    }
}