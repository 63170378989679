.NavItems {
    margin: 0;
    text-align: left;
    padding:0;
    display:flex;
    flex-flow: column;
    align-items: center;
    list-style: none;
}

@media (min-width: 1200px) {
    .NavItems {
        flex-flow: row;
        height: 100%;
        align-items: center;
    }
}
