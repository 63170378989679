.PageText {
    min-height: 400px;
    font-size: 13pt;
    color: black;
    margin: 0 auto;
    margin-top: 80px;
    width: 70%;
    white-space: pre-line;
    margin-bottom:50px;
}

.PageText h2 {
    text-align: center;
}
.Aszf {
    width: 70%;
    margin: 0 auto;
    line-height: 1.9em;
}

.Aszf > ol > li {
    padding: 40px 0;
}