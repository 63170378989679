.Package {
	font-size: 11pt;
	line-height: 1.8em;
	text-align: center;
	width: 90%;
}

.Package:not(:first-child) {
	margin-top: 50px;
}

.PckName {
	color: rgb(114, 87, 87);
	font-weight: bold;
	font-size: 16pt;
	padding: 10px 0;
}

.Icon {
	font-size: 42pt;
	color: rgb(114, 87, 87);
	font-weight: normal;
}

.List {
	list-style: none;
	text-align: left;
	color: gray;
	margin: 0 auto;
	width: 85%;
}

.List span {
	margin-left: 8px;
}

.Good {
	color: #59dbd1;
}

.Bad {
	color: rgb(114, 87, 87);
}

.BadText {
	text-decoration: line-through;
}

.Good :first-child,
.Bad :first-child {
	padding-right: 7px;
}

.Price {
	font-size: 1.1em;
	margin-top: 15px;
	text-align: center;
	color: gray;
}

.Price span {
	color: #59dbd1;
}

@media (min-width: 450px) {
	.List {
		width: 400px;
	}
}

@media (min-width: 800px) {
	.Package {
		width: 30%;
		margin-top: 5px;
	}

	.List {
		margin: 0;
		width: 100%;
	}

	.Package:not(:first-child) {
		margin-top: 5px;
	}

	.Price {
		text-align: left;
	}
}
