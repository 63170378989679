.Footer {
    width: 100%;
    background-color: #292825;
    color: gray;
    display: flex;
    flex-flow: column;
    min-height: 380px;
    font-size: 11pt;    
}

.Footer h3 {
    text-transform: uppercase;
    color: #f9f7e9;
    margin: 30px 0 20px 0;
}

.Footer section {
    text-align: left;
    margin: 0 auto;
    width: 300px;
    line-height: 1.8em;
}

.Links a, .Social a {
    color: gray;
}

.Links a:before {
    content: "> ";
}

.Links a:hover, .Social a:hover {
    color: #94ecdc;
}

.Social a {
    padding-right: 10px;
    margin-right: 15px;
}


@media (min-width: 800px) {
    .Footer {
        flex-flow: row;
        justify-content: center;
    }

    .Footer section {
        width: 400px;
        margin: 0 30px;
        padding: 2  0px;
    }
}