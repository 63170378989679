.Teachers {
	width: 70%;
	margin: 0 auto;
}

.Teachers:before { 
    display: block; 
    content: " "; 
    height: 80px;      /* Give height of your fixed element */
	margin-top: -80px; /* Give negative margin of your fixed element */    
	visibility: hidden; 
}

.TeacherList {
	list-style: none;
	text-align: justify;
}

.TeacherList li {
	display: flex;
	justify-content: space-between;
    flex-flow: column;
    align-items: center;
}

.Text {
	color: gray;
	font-size: 11pt;
	line-height: 1.8em;
}

@media (min-width: 1000px) {
	.TeacherList li {
        flex-flow: row;
        align-items: normal;
	}
}
