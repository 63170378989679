.SideDrawer {
    z-index: 800;
    width: 80%;
    height: 100%;
    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform .3s ease-out;
}

.Active {
    transform: translateX(0);
}

.Inactive {
    transform: translateX(-100%);
}

.NavItems {
    display: flex;
    flex-direction: row;
    width:40px;
}